.react-datepicker__day-names,
.react-datepicker__current-month,
.react-datepicker__triangle {
    @apply hidden;
}

.react-datepicker {
    @apply border-none rounded bg-secondary !important;
}

.react-datepicker__header {
    @apply bg-secondary p-0 !important;
}

.react-datepicker__day {
    @apply text-white text-base m-1.5 !important;
}
.react-datepicker__day--keyboard-selected {
    @apply bg-secondary !important;
}

.react-datepicker__day--selected {
    @apply bg-primary !important;
}

.react-datepicker__day:hover:not(.react-datepicker__day--selected) {
    @apply bg-secondary !important;
}

.react-datepicker__day--outside-month {
    @apply text-white opacity-40 pointer-events-none !important;
}

.react-datepicker__month {
    @apply m-0 py-5 px-6 !important;
}

.react-datepicker-wrapper {
    @apply w-full !important;
}
