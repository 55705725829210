:local(.container):not(:local(.withValue)):not(:hover) :local(.button) > svg {
    @apply stroke-gray-900 fill-white;
}
:local(.container):not(:local(.withValue)):local(.disabled) :local(.button) {
    @apply cursor-default;

    &:local(.button) > svg {
        @apply stroke-gray-900 fill-white;
    }
}

:local(.container):local(.withValue):not(:local(.disabled)):not(:hover) :local(.button),
:local(.container):local(.withValue):local(.disabled) :local(.button) {
    @apply text-yellow cursor-default;

    &:local(.button):local(.active) ~ :local(.button) > svg {
        @apply stroke-gray-900 fill-white;
    }
}

:local(.container):not(:local(.disabled)):hover :local(.button) {
    @apply text-yellow;

    &:local(.button):hover ~ :local(.button) > svg {
        @apply stroke-gray-900 fill-white;
    }
}
