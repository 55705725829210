@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import 'styles/datepicker.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
    @apply h-full;
    overflow-x: hidden;
}

h1 {
    @apply text-2xl font-bold text-gray-900 tracking-tighter;
}

h2 {
    @apply text-lg font-bold text-gray-900 tracking-tighter;
}

h3 {
    @apply text-sm font-bold text-gray-900 tracking-tighter;
}
