:local(.switch) {
    &::after {
        content: '';
        @apply w-5 h-5 rounded-full bg-gray-900 absolute -top-px -left-px transform-gpu transition-all;
    }

    &:checked {
        @apply bg-primary bg-opacity-40;

        &::after {
            @apply translate-x-full;
        }
    }
}
