:local(.card) {
    fill: none !important;
    stroke: none !important;

    &:local(.active) path {
        @apply fill-primary;
    }
}

:local(.base){
  @apply h-12 px-4 w-auto min-w-32 flex flex-row items-center border-gray-300 border ring-primary ring-opacity-50 rounded box-border text-gray-900 transition;

  &>div{
    @apply w-full;
  }
}

:local(.focus){
  @apply border-primary ring-4 ring-primary ring-opacity-50;
}

:local(.invalid){
  @apply text-primary;
}

:local(.ExpiryElement){
  @apply w-auto min-w-32;
}
